export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745895/sollee/Group_609_5.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601687425/general/facebookLogoBlack.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1648745730/sollee/Group_610_9.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1601687425/general/instagramLogoBlack.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651006675/wegrill/about.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651005458/wegrill/footer.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651003971/wegrill/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648711705/sollee/NOW_TAKING_ONLINE_ORDERS_8.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651004065/wegrill/logo2.jpg'
export const logoMobile =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648711758/sollee/Group_1321.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651004960/wegrill/map.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652203207/wegrill/mobileAbout.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651086545/wegrill/mobileContact.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651006772/wegrill/mobileHero.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651086435/wegrill/mobileMap.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648744510/sollee/NOW_TAKING_ONLINE_ORDERS_9.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652204422/wegrill/mobilePromo2.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1651004344/wegrill/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652204422/wegrill/promo2.jpg'
export const mobileAboutDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648710686/sollee/bibimbap-dark-concrete-background-traditional-korean-dish-top-view_3_1.jpg'
export const aboutDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1648710513/sollee/Mask_group_55_1.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652202224/wegrill/foodGallery2.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652202224/wegrill/mobileGallery2.jpg'
