import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, aboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%">
          <CFImage src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="500px"
          image={`url(${about}) 20% 0 / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
